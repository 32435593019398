<template>
    <router-view></router-view>
</template>

<script>
// import Home from './view/Home';
// import OrderList from './view/OrderList';
// import Product from './view/Product';

export default {
  name: 'App',
  components: {
    // // eslint-disable-next-line vue/no-unused-components
    // Home,
    // // eslint-disable-next-line vue/no-unused-components
    // OrderList,
    // // eslint-disable-next-line vue/no-unused-components
    // Product
  },
}
</script>

<style>
  .el-message{
    min-width: 200px;
    background: rgb(0, 0, 0, 0.4);
    border:0;
    border-radius: 10px;
  }
  .el-message .el-icon-info{
    color: white;
  }
  .el-message--info .el-message__content{
    color: white;
  }
</style>
