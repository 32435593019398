import VueRouter from "vue-router";

const routes = [
  {
    path:'/',
    component: () => import('../view/ZhengXin')
  },
  {
    name:'zhengXin',
    path:'/index',
    component: () => import('../view/ZhengXin')
  },
  {
    name:'protocol',
    path:'/protocol',
    component: () => import('../view/Protocol')
  },
  {
    name:'aboutUs',
    path:'/aboutUs',
    component: () => import('../view/AboutUs')
  },
  {
    name:'contactUs',
    path:'/contactUs',
    component: () => import('../view/ContactUs')
  },
  {
    name:'payLoading',
    path:'/payLoading',
    component: () => import('../view/PayLoading')
  },
  {
    name:'pay',
    path:'/pay',
    component: () => import('../view/Pay')
  },
  {
    name:'order',
    path:'/order',
    component: () => import('../view/Order')
  },
  {
    name:'templateInfo',
    path:'/templateInfo',
    component: () => import('../view/TemplateInfo')
  },
  {
    name:'report',
    path:'/report',
    component: () => import('../view/Report')
  },
];

export default new  VueRouter({
  mode:'history',
  routes
})